// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 34vh;
	min-height: 300px;

	.owl-nav,
	.owl-dots,
	.owl-subtitle,
	.owl-description,
	.owl-title {
		display: none;
	}
	.owl-caption-holder {
		height: 100%;
		display: flex;
		align-items: center;
		padding: 0;
		height: 100%;
		position: relative;

		.owl-caption {
			position: absolute;
			bottom: 0;
			right: 10%;
			display: flex;
			width: 123px;
			aspect-ratio: 1/1;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			background: $yellow;
			transform: translate(-40%, 50%) rotate(-19deg);
			transition: 0.5s ease;
			overflow: visible;
			.owl-btn {
				text-align: center;
				font-size: 20px !important;
				color: $black;
				font-weight: 600;
				line-height: 22px;
				overflow: visible;
				background: none;
				border: 0;
			}

			&:hover {
				transform: translate(-40%, 48%) rotate(0) scale(1.01);

				transition: 0.5s ease;
			}
		}
	}
}

section.eyecatcher {
	max-width: $desktop-width;
	margin: auto;
	box-shadow: 0px -10px 20px #00000029;
	position: relative;

	.owl-carousel {
		z-index: 3 !important;
		.owl-stage-outer {
			padding-bottom: 65px;
		}
	}
	@include media-breakpoint-down(md) {
		max-width: $mobile-width;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 35vh;
	min-height: 340px;
	max-height: 100vh;

	@include media-breakpoint-up(sm) {
		min-height: 50vh;
	}
	.owl-nav,
	.owl-dots,
	.owl-subtitle,
	.owl-description,
	.owl-btn {
		display: none !important;
	}
	.owl-container {
		padding: 0;
		max-width: 100%;
	}
	.owl-caption-holder {
		height: 100%;
		display: flex;
		align-items: flex-end;
		padding: 0;
		height: 100%;
		.owl-caption {
			position: relative !important;
			margin: 0 !important;
			text-align: left;
			height: 100%;
			background: none;
			width: 100%;
			border-radius: 0;
			aspect-ratio: unset;
			transform: none;
			text-align: left;
			justify-content: flex-start;
			right: 0;
			.owl-title {
				display: block !important;
				margin-left: 2rem;
				margin-bottom: 6vh;
				font-size: 48px;
				max-width: 55%;
				@include media-breakpoint-down(md) {
					font-size: 30px;
				}
			}
		}
		@include media-breakpoint-down(md) {
			align-items: center;
		}
	}
}
