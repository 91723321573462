// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors

$blue-lighter: #d8edee;
$blue-light: #d3ecf5;
$blue: #58bddc;
$blue-dark: #00a8cd;
$blue-green: #5b94a9;
$green-dark: #008996;
$green: #d2d6a8;
$yellow: #ffe400;
$red: #c8746e;
$white: #fff;
$black: #010000;

// theme-colors
$primary: $green-dark;
$secondary: $blue-light;

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $black;
$link-decoration: none;
$link-hover-color: darken($link-color, 50%);

// typography
@import url("https://fonts.googleapis.com/css2?family=Radio+Canada:ital,wght@0,300..700;1,300..700&display=swap");
$font-family-primary: "Radio Canada", sans-serif;

$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 2; // 32px
$h3-font-size: $font-size-base * 1.75; // 28px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-primary;
$headings-font-weight: 700;
$headings-color: $blue;

// contextual
$light: $green;
$dark: $green-dark;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;

// shadow
$shadow: 6px 6px 20px #00000040;

// main widths:
$desktop-width: 70%;
$mobile-width: 95%;
/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
