div.social-media {
    ul.social-media {
        gap: 10px;

        a {
            background-color: $primary;
            color: $white;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1rem;
            border-radius: 50%;
            text-decoration: none;
            overflow: hidden;
            position: relative;

            &:after {
                background: $white;
                content: "";
                height: 200%;
                left: -150%;
                opacity: 0.2;
                position: absolute;
                top: -50%;
                transform: rotate(135deg);
                transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
                width: 10px;
            }
            &:hover {
                &:after {
                    left: 150%;
                    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
                }
            }

            i {
                line-height: normal;
            }
        }

        .list-item-title {
            display: none;
        }
    }
}