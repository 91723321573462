// footer
.footer {
	position: relative;
	padding-top: 36vh;
	background: $white;
	margin-top: -30vh;
	z-index: 1;
	&::before {
		content: "";
		background: url("/images/main-bg-bottom.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 50vh;
		transform: translateY(-50%);
	}
	.footer-outro {
		padding-bottom: 3vh;
		.container {
			max-width: 70%;
			.column {
				max-width: 20%;
				flex-basis: 20%;
				font-size: 14px;
				.footer-text {
					a {
						color: $blue-dark;
						text-decoration: underline;
					}
				}
				h6,
				h5,
				h4,
				h3,
				h2,
				h1 {
					color: $black;
					font-size: 16px;
					font-weight: 600;
					margin-bottom: 0.65rem;
				}
				&.six {
					display: none;
				}
			}
		}
	}
	.footer-socket {
		padding-bottom: 1rem;
		.container {
			max-width: 70%;
		}
		.list {
			display: flex;
			gap: 0.75rem;
			li {
				a {
					font-size: 14px;
					&::after {
						content: "/";
						margin-left: 0.75rem;
					}
				}
				&:last-child() {
					a::after {
						display: none;
					}
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.footer-outro {
			padding-bottom: 3vh;

			.container {
				max-width: 100%;

				.column {
					max-width: 50%;
					flex-basis: 50%;
					font-size: 14px;
				}
			}
		}
		.footer-socket {
			.container {
				max-width: 100%;
			}
			.list {
				flex-flow: column;
				gap: 0.15rem;
				li {
					a::after {
						display: none;
					}
				}
			}
		}
	}
}
