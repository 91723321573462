// header
header.header {
	z-index: 100;
	position: relative;
	background: $white;
	transition: 0.5s;
	max-width: $desktop-width;
	margin: 6vh auto 0 auto;
	border-radius: 20px 20px 0 0;
	box-shadow: 0px -10px 20px #00000029;
	@include media-breakpoint-down(md) {
		max-width: $mobile-width;
		display: none;
	}
	&.sticky {
	}
	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
		&.top {
			.container-holder {
				.column.two {
					display: flex;
					justify-content: flex-end;
					align-items: flex-end;
					padding: 20px 40px;
					position: relative;
					div.secondary-menu {
						position: absolute;
						top: 20px;
						right: 40px;
						ul.secondary-menu {
							display: flex;
							gap: 1rem;
							li {
								display: flex;
								align-items: center;
								font-size: 14px;
								&::after {
									content: "|";
									margin-left: 1rem;
								}
								&:nth-last-child(1) {
									&::after {
										display: none;
									}
								}
							}
						}
					}
					div.contact-list {
						@include media-breakpoint-up(xl) {
							margin-right: 1.5rem;
						}
						ul {
							li a {
								display: flex;
								flex-flow: row wrap;
								column-gap: 0.35rem;
								align-items: center;
								justify-content: flex-end;
								.list-item-title {
									color: $primary;
									font-size: 16px;
									font-weight: 600;
								}
								i {
									color: $primary;
									font-size: 14px;
									transition: 0.35s ease;
								}
								.list-item-suffix {
									flex-basis: 100%;
									text-align: end;
									font-size: 12px;
								}
								&:hover {
									text-decoration: none;
									i {
										transform: scaleX(-1);
										transition: 0.35s ease;
									}
									.list-item-suffix {
										font-weight: 600;
										transition: 0.35s ease;
									}
								}
							}
						}
					}
				}
			}
			div.book-button {
				a {
					@extend .btn, .btn-primary;
				}
			}
		}
		&.bottom {
			padding-left: 0;
			padding-right: 0;
		}
	}

	// menu-toggle
	.menu-toggle {
	}

	// logo
	.logo {
		width: 116px;
		margin: 40px 40px 40px 20px;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
		}

		@include media-breakpoint-up(xl) {
			background: $green;
			min-height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			.navbar-nav {
				display: flex;
				justify-content: center;
				gap: 1rem;
				.nav-item {
					.nav-link {
						font-size: 14px;
						font-weight: 600;
					}
					&.active,
					&:hover {
						.nav-link {
							color: $white;
						}
					}
				}
				.dropdown-menu {
					display: block;
					overflow: hidden;
					height: 0px;
					max-height: 0px;
					border: none;
					box-shadow: none;
					margin-top: 1rem;
					background: $green;
					min-width: 225px;
					padding: 0.55rem;
					opacity: 0;
					border-radius: 3px;
					&::after,
					&::before {
						display: none;
					}
					.nav-item {
						padding-left: 0.65rem;
						margin-top: 0.25rem;
						margin-bottom: 0.25rem;

						.nav-link {
							color: $black;
							font-size: 12px;
							font-style: italic;
						}

						&.active,
						&:hover {
							.nav-link {
								color: $white;
							}
						}
					}
					&.show {
						height: auto;
						max-height: 200px;
						opacity: 1;
						transition: 0.35s ease;
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
	}
}
header.header-mobile {
	display: block;
	z-index: 998;
	position: relative;
	background: $white;
	transition: 0.5s;
	max-width: $mobile-width;
	margin: 6vh auto 0 auto;
	border-radius: 20px 20px 0 0;
	box-shadow: 0px -10px 20px #00000029;
	.container-fluid {
		max-width: 100%;
		.container-holder {
			display: flex;
			flex-flow: row nowrap;
			.column {
				max-width: 33% !important;
				flex-basis: 33% !important;
				padding-top: 20px;
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				position: relative;
				&.one {
					max-width: 40% !important;
					flex-basis: 40% !important;
					.logo {
						max-width: 108px;
						margin: 0;
					}
				}
				&.two {
					max-width: 20% !important;
					flex-basis: 20% !important;
					.navbar-toggler {
						display: block;
						background: $primary;
						border-radius: 50%;
						padding: 5px;
						aspect-ratio: 1/1;
						width: 38px;
						i {
							color: $white;
							transition: 0.5s ease;
						}
						&[aria-expanded="true"] {
							i {
								transform: rotate(360deg);
								transition: 0.5s ease;
								&::before {
									content: "\f00d";
								}
							}
						}
					}
				}
				&.three {
					max-width: 40% !important;
					flex-basis: 40% !important;
					overflow: visible;
					div.book-button {
						position: absolute;
						top: 20px;
						right: 10px;
						a {
							display: flex;
							justify-content: center;
							width: 100%;
							background: $primary;
							border-radius: 25px;
							font-size: 14px;
							font-weight: 600;
							font-style: italic;
							color: $white !important;
							padding: 0.5rem 0.85rem;
						}
					}
					div.header-contact {
						position: absolute;
						right: 10px;
						bottom: 0;
						// width: 200%;
						white-space: nowrap;

						ul {
							li a {
								display: flex;
								flex-flow: row wrap;
								column-gap: 0.35rem;
								align-items: center;
								justify-content: flex-end;

								.list-item-title {
									color: $primary;
									font-size: 16px;
									font-weight: 600;
								}

								i {
									color: $primary;
									font-size: 14px;
									transition: 0.35s ease;
								}

								.list-item-suffix {
									flex-basis: 100%;
									text-align: end;
									font-size: 12px;
								}

								&:hover {
									text-decoration: none;

									i {
										transform: scaleX(-1);
										transition: 0.35s ease;
									}

									.list-item-suffix {
										font-weight: 600;
										transition: 0.35s ease;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.menu {
		display: block;
		padding: 1rem 5px;

		.navbar-nav {
			.nav-item {
				border-top: 1pt solid $blue-light;
				.nav-link {
					font-size: 14px;
					font-weight: 600;
					font-style: italic;
					color: $primary;
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 10px !important;
				}
				.dropdown-toggle {
					&::after {
						content: "\f078";
						font-size: 15px;
						color: $primary;
						border: none !important;
						font-family: $font-awesome;
						font-style: normal;
						transition: 0.5s ease;
					}
					&[aria-expanded="true"] {
						&::after {
							transform: scaleY(-1);
							transition: 0.5s ease;
							color: $red;
						}
					}
				}
				&.show,
				&.active {
					> .nav-link {
						color: $red;
					}
				}
				.dropdown-menu.show {
					position: static !important;
					transform: none !important;
					border: 0 !important;
					box-shadow: none !important;
					padding: 0 !important;
					margin: 0 !important;
					.nav-item {
						border: 0 !important;
						padding: 0;
						.nav-link {
							font-size: 12px;
							font-style: italic;
							font-weight: 600;
							padding: 5px 10px !important;
						}
					}
				}
			}
		}
		&.collapse {
			display: none;
		}
	}

	@include media-breakpoint-up(lg) {
		max-width: $mobile-width;
		display: none;
	}
}
