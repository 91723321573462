// main
&.default {
	&::before {
		content: "";
		top: 30vh;
		left: 0;
		height: 50vh;
		width: 100%;
		background: url("/images/main-bg-top.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		position: absolute;
	}
	&::after {
		content: "";
		top: 50%;
		left: 0;
		height: 50vh;
		width: 100%;
		background: url("/images/main-bg-middle.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		position: absolute;
	}
	position: relative;
	background: $blue-light;
}

main {
	max-width: $desktop-width;
	margin: 0 auto;
	box-shadow: 0px 10px 20px #00000029;
	border-radius: 0 0 20px 20px;
	background: $white;
	padding-bottom: 3vh;
	position: relative;
	z-index: 2;
	@include media-breakpoint-down(md) {
		max-width: $mobile-width;
	}
}
// section

// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
	max-width: 95%;
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// card
.card {
	box-shadow: none;
	border: none;
	position: relative;
	overflow: visible;
	padding-bottom: 0;
	.card-image,
	.card-image picture,
	.card-image img {
		padding: 0;
		border-radius: 8px;
		overflow: hidden !important;
		position: relative;
	}

	.card-body {
		padding: 0.5rem 0;
		.card-caption {
			padding-top: 0.75rem;
			display: flex;
			flex-flow: column;
		}
	}

	.card-title {
		color: $green-dark !important;
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 0 !important;
	}

	.card-subtitle {
		order: -1;
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 2px !important;
	}

	.card-description {
		display: flex;
		flex-flow: column;
		margin-bottom: 1.5rem;
		margin-top: 1rem;
		.list {
			order: -1;
			display: flex;
			flex-flow: column;
			gap: 0.25rem;
			margin-top: -0.8rem !important;
			padding-bottom: 1rem;
			li {
				display: flex;
				gap: 0.5rem;

				&:last-child() {
					&::after {
						display: none;
					}
				}
			}
			@include media-breakpoint-down(md) {
				flex-flow: column;
				gap: 0;
			}
		}
	}

	.card-buttons {
		position: relative;
		transform: none;
		left: 0;
		.btn {
			padding: 0;
			background: none;
			color: $green-dark;
			font-size: 16px;
			font-weight: 600;
			border: none;
			display: flex;
			gap: 0.5rem;
			flex-flow: row;
			align-items: center;
			font-style: normal;
			@extend .btn;
			&::before {
				content: "\f054";
				font-family: $font-awesome;
				font-size: 12px;
				order: 1;
				transition: 0.35s;
			}
		}
	}
}
.default-card {
	box-shadow: $shadow;
	border-radius: 10px;
	position: relative;
	overflow: visible;
	padding-bottom: 5vh;

	div.card-image {
		padding: 1rem;
		border-radius: 8px;
		overflow: hidden;
		img {
			border-radius: 8px;
			overflow: hidden;
		}
	}
	.card-body {
		padding: 0.5rem 2rem;
	}
	.card-title {
		color: $blue !important;
		font-size: 20px;
		font-weight: bold;
	}
	.card-subtitle {
		font-size: 16px;
		font-weight: 600;
	}
	.card-buttons {
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translate(-50%, 50%);
		.card-btn {
			@extend .btn, .btn-primary;
			&::before {
				display: none;
			}
		}
	}
	.card-description {
		display: flex;
		flex-flow: column;
		margin-bottom: 1.5rem;
		margin-top: 1rem;

		.list {
			order: -1;
			display: flex;

			margin-top: -0.8rem;
			padding-bottom: 1rem;

			flex-flow: column;
			gap: 0;

			li {
				display: flex;
				gap: 0.5rem;

				&:last-child() {
					&::after {
						display: none;
					}
				}
			}
		}
	}
}

.card.default {
	@extend .default-card;
	div.card {
		position: relative;
	}
	.card-subtitle {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		width: 123px;
		aspect-ratio: 1/1;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background: $yellow;
		transform: translate(-2rem, 2rem) rotate(-19deg);
		transition: 0.5s ease;
		overflow: hidden;
		text-align: center;
		font-size: 18px !important;
		color: $black;
		font-weight: 600;
		line-height: 22px;
		padding: 5px !important;

		@include media-breakpoint-down(md) {
			font-size: 12px !important;
			width: 100px;
			padding: 3px !important;
		}
	}
	&:hover {
		.card-subtitle {
			transform: translate(-2rem, 2rem) rotate(0) scale(1.01);
			transition: 0.5s ease;
		}
	}
}

// grid-items
.grid-items {
	row-gap: 2rem;
	@include media-breakpoint-down(md) {
		row-gap: 1rem;
		.item {
			padding-left: 0 !important;
			padding-right: 0 !important;
			max-width: 92% !important;
		}
	}
}

// image border
.gallery-img {
	border-radius: 8px;
}
