div.random-icons {
    ul.random-icons {
        justify-content: center;
        li {
            display: none;
            
            &.active {
                display: block;
            }

            .list-item-title {
                display: none;
            }
        }
    }
}