// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}
// usp top bar
.usp-bar-section {
	position: absolute;
	top: -4vh;
	width: 100%;
	div.top-usp-list {
		width: 100%;
		ul {
			display: flex;
			gap: 1.5rem;
			justify-content: center;
			width: 100%;
			li {
				font-size: 14px;
				transition: 0.5s ease;
				&::before {
					content: "\f00c";
					font-family: $font-awesome;
					margin-right: 5px;
					color: $green-dark;
					font-size: 14px;
				}
				.list-item-title {
					font-weight: 700;
					margin-right: 1ch;
				}
				&.active {
					transform: scale(1.05);
					transition: 0.5s ease;
				}

				@include media-breakpoint-down(md) {
					position: absolute;
					opacity: 0;
					transition: 0.5s ease;
					&.active {
						opacity: 1;
						transition: 0.5s ease;
					}
				}
			}
		}
	}
}

// home intro section
.home-intro-section {
	margin: 0 0;
	padding: 6vh 0;

	.container {
		.container-holder {
			max-width: 700px;
			margin: 0 auto;
			text-align: center;
			@include media-breakpoint-down(md) {
				max-width: 100%;
			}
			.image {
				margin: 0 auto 1rem auto;
				max-width: 83px;
			}

			.description {
				font-size: 18px;
				color: $black;
			}
			div.usp-tags {
				ul {
					display: flex;
					gap: 1rem;
					justify-content: center;
					li {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 3px;
						background: $blue-light;
						border-radius: 3px;
						padding: 0.65rem 1rem;
						font-size: 14px;
						transition: 0.35s ease;
						@include media-breakpoint-down(md) {
							width: 45%;
							font-size: 11px !important;
						}
						i,
						img {
							margin-right: 0.5rem;
							color: $white;
							font-size: 24px;
							max-width: 24px;
						}
						a {
							text-decoration: none;
							display: flex;
							align-items: center;
							justify-content: center;
						}
						&:hover {
							background: $blue;
							transition: 0.35s ease;
							span {
								color: $white;
							}
							i,
							img {
								transform: rotate(360deg);
								transition: 0.35s ease;
							}
						}
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			margin-top: 23vh;
		}
	}
	.grid-container {
		margin: 6vh 0;
		padding-right: 40px;
		padding-left: 40px;
		.item {
			@include media-breakpoint-down(md) {
				max-width: 100% !important;
			}
		}
		.card {
			@extend .default-card;
			div.card {
				position: relative;
			}
			.card-subtitle {
				position: absolute;
				top: 0;
				right: 0;
				display: flex;
				width: 123px;
				aspect-ratio: 1/1;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				background: $yellow;
				transform: translate(-2rem, 2rem) rotate(-19deg);
				transition: 0.5s ease;
				overflow: hidden;
				text-align: center;
				font-size: 18px !important;
				color: $black;
				font-weight: 600;
				line-height: 22px;
				padding: 5px !important;

				@include media-breakpoint-down(md) {
					font-size: 12px !important;
					width: 100px;
					padding: 3px !important;
				}
			}
			&:hover {
				.card-subtitle {
					transform: translate(-2rem, 2rem) rotate(0) scale(1.01);
					transition: 0.5s ease;
				}
			}
		}
	}
}

// home accommodation section
.home-accommodation-section {
	padding: 0;

	.container {
		.container-holder {
			max-width: 700px;
			margin: 0 auto;
			text-align: center;

			.image {
				margin: 0 auto 1rem auto;
			}

			.description {
				font-size: 18px;
				color: $black;
			}
		}
	}
	.grid-container {
		margin: 6vh 0 0 0;
		padding-right: 40px;
		padding-left: 40px;
		.item {
			@include media-breakpoint-down(md) {
				max-width: 100% !important;
			}
		}
	}
	div.more-link {
		display: flex;
		justify-content: center;
		height: 100%;
		margin-top: 6vh;
		a {
			@extend .btn, .btn-primary;
			font-style: italic;
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 0 0;
	padding: 6vh 0;

	.container-one-column {
		.container-holder {
			text-align: center;
		}
		img {
			max-width: 100px;
			margin: 15px;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
				&::after {
					content: "|";
					margin-left: 25px;
				}
				&:last-child() {
					&::after {
						display: none;
					}
				}
			}

			.icon {
				margin-right: 5px;
			}
		}
		.wysiwyg {
			max-width: 75%;
			margin: auto;
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
