.mini-sab {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 100;
	width: 100%;
	max-width: fit-content;
	margin-left: auto;
	margin-right: 3rem;
	margin-bottom: 3rem;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 6px 6px 20px #00000040;
	border-radius: 10px;
	max-width: fit-content;
	padding: 2rem;
	.mini-search-book {
		.heading {
			font-size: 20px;
			font-weight: 600;
			color: $blue;
			margin-bottom: 0.5rem;
		}
	}
	div.sab-sticker {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		width: 123px;
		aspect-ratio: 1/1;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background: $yellow;
		transform: translate(-55%, -70%) rotate(-19deg);
		transition: 0.5s ease;
		text-align: center;
		font-size: 20px !important;
		color: $black;
		font-weight: 600;
		line-height: 22px;
		border: 0;
		&:hover {
			transform: translate(-55%, -70%) rotate(0) scale(1.05);
		}
	}
	button.widget-btn {
		@extend .btn, .btn-primary;
	}
	@include media-breakpoint-down(md) {
		position: relative;

		margin: auto;
		transform: translateY(-50%);
		div.sab-sticker {
			width: 90px;
			left: 20%;
			font-size: 14px !important;
		}
	}
}
