// btn
.btn {
	font-weight: 600;
	font-size: 14px;
	padding: 0.5rem 1.25rem;
	border-radius: 35px;
	overflow: hidden;
	position: relative;
	&:after {
		background: $white;
		content: "";
		height: 100%;
		left: -120%;
		opacity: 0.2;
		position: absolute;
		top: 0%;
		transform: rotate(35deg);
		transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
		width: 100%;
	}
	&:hover {
		&:after {
			left: 120%;
			transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
		}
	}
	// btn-primary
	&.btn-primary {
		&:hover {
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
	padding-left: 1rem;
	@extend .btn, .btn-primary;
	&::before {
		content: "\f060";
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
